export const TIME_MACHINE_ROUTE = "time-machine";
export const DASHBOARD_ROUTE = "dashboard";
export const DASHBOARD_ROUTE_SPECIFIC = "dashboard/:userName";
export const TRANSACTION_ROUTE = "transaction";
export const MANAGE_CLIENT_AND_TEAM_ROUTE = "manage-client-and-team";
export const SETTINGS_ROUTE = "settings";
export const EXIT_PLAN_DETAILS_ROUTE_SPECIFIC =
  "exit-plan/details/:userName/:id";
export const EXIT_PLAN_DETAILS_ROUTE = "exit-plan/details/:id";
export const EXIT_PLAN_ROUTE = "exit-plan";
export const CRYPTO_RATING_ROUTE = "crypto-rating";
export const MY_PROFILE_ROUTE = "my-profile";
export const MANAGED_USER_ROUTE = "managed-user";
export const TRANSACTION_ROUTE_SPECIFIC = "transaction/:userName";
export const TIME_MACHINE_SPECIFIC = "time-machine/:userName";
export const SETTINGS_ROUTE_SPECIFIC = "settings/:userName";
export const EXIT_PLAN_ROUTE_SPECIFIC = "exit-plan/:userName";
export const PRICE_CALCULATOR_ROUTE = "price-calculator";