export const LIVEPRICE = ["live-price"];
export const CURRENCY = ["currency"];
export const ASSET = "asset";
export const SNAPSHOT = "snapshot";
export const USER = ["user"];
export const MANAGER_BY_ACCOUNT = "managerByAccount";
export const API_KEY = "api-key";
export const WALLET = "wallet";
export const PORTFOLIO_SNAPSHOT = "portfolio-snapshot";
export const TRANSACTION = "transaction";
export const SEARCH_USER = "search-user";
export const EXIT_PLAN_KEY = "exit-plan";
export const LOGO_CRYPTO = "logo-crypto";
export const GET_CRYPTO = "get-crypto";
