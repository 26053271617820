import axios from "axios";
import { CryptoRatingRouteAxios, RouteAxios } from "./axios";
import {
  TCrypto,
  TCurrency,
  TFetchPriceCrypto,
  TLivePrice,
  TLogo,
} from "../types/others";

export async function getUsdPrice(asset: string): Promise<number> {
  let price: number = 1;
  try {
    const url: string = `https://api.coinbase.com/v2/prices/${asset}-USD/spot`;
    const response = await axios.get(url);
    price = response.data.data.amount;
  } catch (error) {
    console.error(error);
  }
  return parseFloat(price.toString());
}

export const getLogoCrypto = async (symbol: string) => {
  return (
    await CryptoRatingRouteAxios.get<TLogo>(`/cryptorating/logo/${symbol}`)
  ).data;
};

export const getCurrecieEurUsdt = async () => {
  return (
    await axios.get<TCurrency>(
      "https://api.binance.com/api/v3/ticker/price?symbol=EURUSDT"
    )
  ).data;
};

export const getLivePrice = async () => {
  return (await RouteAxios.get<TLivePrice>("/live-price")).data;
};

export const getCrypto = async () => {
  return (await RouteAxios.get<TCrypto>("/all-asset-for-account")).data;
};

export const fetchPriceCrypto = async (crypto: string, date: string) => {
  return (
    await RouteAxios.post<TFetchPriceCrypto>("/fetch-price", {
      date: date,
      symbol: crypto,
    })
  ).data;
};
