import {
  extraRoutes,
  otherRoutes,
  managerRoutesOnly,
  clientManagementRoutes,
  clientManagementRoutesSelected,
  userSelectedAndUserNoManagerOnlyRoutes,
} from "./route";
import React, { useEffect } from "react";
import { selectUser } from "../redux/selector";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD_ROUTE } from "../constantes/routes";
import { SET_TOGGLE_EURO_DOLLAR } from "../constantes/redux";
import { useGetCurrency } from "../hook/useGetCurrencyEurUsdt";
import { Routes, Route, Navigate, Outlet, useParams } from "react-router-dom";

// components
const Layout = React.lazy(() => import("../layout"));
const Login = React.lazy(() => import("../views/Login"));

export default function Navigation() {
  const userConnected = useSelector(selectUser).connected;

  const currency = useGetCurrency();

  const dispatch = useDispatch();

  useEffect(() => {
    if (currency.isSuccess) {
      dispatch({
        type: SET_TOGGLE_EURO_DOLLAR,
        payload: { valueEuroDollar: 1 / parseFloat(currency.data.price) },
      });
    }
  }, [currency, dispatch]);

  return (
    <Routes>
      <Route
        path="/"
        element={userConnected ? <Navigate to={DASHBOARD_ROUTE} /> : <Login />}
      />
      <Route
        path="/*"
        element={
          <LayoutWrapper userConnected={userConnected}>
            <Outlet />
          </LayoutWrapper>
        }
      >
        {/* Ajoute les sous-routes de Layout */}
        {extraRoutes.map((route, index) => (
          <>
            <Route
              key={index}
              path={route.path}
              element={userConnected ? <route.element /> : <Navigate to="/" />}
            />
            {route.pathSpecific && (
              <Route
                key={`${index}_`}
                path={route.pathSpecific}
                element={
                  userConnected ? <route.element /> : <Navigate to="/" />
                }
              />
            )}
          </>
        ))}
        {clientManagementRoutes.map((route, index) => (
          <>
            <Route
              key={index}
              path={route.path}
              element={userConnected ? <route.element /> : <Navigate to="/" />}
            />
            {route.pathSpecific && (
              <Route
                key={`${index}_`}
                path={route.pathSpecific}
                element={
                  userConnected ? <route.element /> : <Navigate to="/" />
                }
              />
            )}
          </>
        ))}
        {managerRoutesOnly.map((route, index) => (
          <>
            <Route
              key={index}
              path={route.path}
              element={userConnected ? <route.element /> : <Navigate to="/" />}
            />
            {route.pathSpecific && (
              <Route
                key={`${index}_`}
                path={route.pathSpecific}
                element={
                  userConnected ? <route.element /> : <Navigate to="/" />
                }
              />
            )}
          </>
        ))}
        {clientManagementRoutesSelected.map((route, index) => (
          <>
            <Route
              key={index}
              path={route.path}
              element={userConnected ? <route.element /> : <Navigate to="/" />}
            />
            {route.pathSpecific && (
              <Route
                key={`${index}_`}
                path={route.pathSpecific}
                element={
                  userConnected ? <route.element /> : <Navigate to="/" />
                }
              />
            )}
          </>
        ))}
        {otherRoutes.map((route, index) => (
          <>
            <Route
              key={index}
              path={route.path}
              element={userConnected ? <route.element /> : <Navigate to="/" />}
            />
            {route.pathSpecific && (
              <Route
                key={`${index}_`}
                path={route.pathSpecific}
                element={
                  userConnected ? <route.element /> : <Navigate to="/" />
                }
              />
            )}
          </>
        ))}
        {userSelectedAndUserNoManagerOnlyRoutes.map((route, index) => (
          <>
            <Route
              key={index}
              path={route.path}
              element={userConnected ? <route.element /> : <Navigate to="/" />}
            />
            {route.pathSpecific && (
              <Route
                key={`${index}_`}
                path={route.pathSpecific}
                element={
                  userConnected ? <route.element /> : <Navigate to="/" />
                }
              />
            )}
          </>
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}

function LayoutWrapper({
  children,
  userConnected,
}: {
  children: React.ReactNode;
  userConnected: boolean | undefined;
}) {
  const { userName } = useParams();

  document.title = userName
    ? `Asset Management | ${userName}`
    : "Asset | Management";

  return <Layout userName={userName}>{children}</Layout>;
}
